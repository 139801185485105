const Config = {
    BASE_URL: 'https://www.crickingdom.org/api',
    AUTH_URL : 'https://www.crickingdom.org/api-token-auth/',
    RAZORPAY_KEY : 'rzp_live_YTJzKyBoWHooDM',
    INR: 'INR',
    USERPOORID: 'ap-southeast-1_qdLBV678Q',
    CLIENTID: 'nqd39e8p3b3024f2dq272l3va',
    LOGIN_FB: 'https://auth.sportskingdom.io/oauth2/authorize?identity_provider=Facebook&redirect_uri=https://app.crickingdom.com/&response_type=TOKEN&client_id=nqd39e8p3b3024f2dq272l3va&scope=aws.cognito.signin.user.admin%20email%20openid%20phone%20profile',
    RESET: 'https://reset.sportskingdom.io/',
    USERPOOL_DOMINE: 'https://auth.sportskingdom.io'
}

export default Config;
