import React from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/styles';
import ArrowBack from '@material-ui/icons/ArrowBack';
import { ThemeProvider } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { CognitoUser } from "amazon-cognito-identity-js";
import UserPool from '../../shared/UserPool';

let theme = createMuiTheme({
    palette: {
        primary: {
            main: '#474d6c', // #dusk
            light: '#eef2fb', // #pale_grey
            dark: '#292929', // #black
            contrastText: '#fff',
            facebook: '#14548f'// ##light_navy
        },
        secondary: {
            // This is green.A700 as hex.
            main: '#ed1c24', //  #pinkish_red
            light: '#e1e1e1', //#white_two
            contrastText: '#fff',
            dark: '#292929', // #black
            facebook: '#14548f'// ##light_navy
        },
    }
});
theme = responsiveFontSizes(theme);

const styles = ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    textBox: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3),
    },
    media: {
        margin: 10,
        width: 180,
        height: 40,
    },
    topGrid: {
        backgroundColor: '#ed1c24',
        marginBottom: theme.spacing(2),
        padding: '45px',
        borderBottomRightRadius: '200px',
        width: 'auto',
        [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    }
})





class ResetPassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            emailMobile: '',
            newpassword1: '',
            newpassword2: '',
            code: '',
            page: 0,
            code_destination: '',
            invalid: false,
            invalidPassword: false,
            passwordError: "Password should be longer than 6"
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleBack = this.handleBack.bind(this);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value,
            invalid: false,
            invalidPassword: false
        });
    }

    handleBack(event) {
        this.setState({
            page: 0
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        let cognitoUser = new CognitoUser({
            Username: this.state.emailMobile,
            Pool: UserPool
        });

        if (this.state.page === 0) {
            var self = this;
            cognitoUser.forgotPassword({
                onSuccess: function (data) {
                    self.setState({
                        code_destination: data.CodeDeliveryDetails.Destination,
                        page: 1,
                    });
                },
                onFailure: function (err) {
                    self.setState({
                        invalid: true
                    });
                },
            });
        } else if (this.state.page === 1) {
            if (this.state.code.length < 1) {
                this.setState({
                    invalid: true
                });
            } else if (this.state.newpassword1.length < 6) {
                this.setState({
                    invalidPassword: true,
                    passwordError: 'Password should be longer than 6 letters'
                });

            } else if (this.state.newpassword1 !== this.state.newpassword2) {
                this.setState({
                    invalidPassword: true,
                    passwordError: 'Passwords does not match'
                });
            }
            else {
                var self1 = this;
                cognitoUser.confirmPassword(this.state.code, this.state.newpassword1, {
                    onSuccess() {
                        self1.setState({
                            page: 2
                        });
                    },
                    onFailure(err) {
                        self1.setState({
                            invalid: true
                        });
                    },
                });

            }
        }
    }




    render() {
        const classes = this.props.classes;

        return (
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="xs">

                    <CssBaseline />
                    <div>
                        <Grid className={classes.topGrid}>
                            <CardMedia
                                className={classes.media}
                                image={require("../../assets/logo.png")}
                                title="CricKingdom"
                            />
                        </Grid>

                        {(this.state.page === 0 || this.state.page === 1) && <Typography variant="h4" gutterBottom>
                            Password Reset
                    </Typography>}
                        <form onSubmit={this.handleSubmit} className={classes.form} noValidate>
                            <Grid container spacing={2}>
                                {(this.state.page === 0) && <Grid item xs={12}>
                                    <TextField
                                        className={classes.textBox}
                                        variant="outlined"
                                        fullWidth
                                        id="emailMobile"
                                        label="Email"
                                        name="emailMobile"
                                        value={this.state.emailMobile}
                                        onChange={this.handleInputChange}
                                        error={this.state.invalid && this.state.emailMobile < 1}
                                        helperText={this.state.invalid && this.state.emailMobile < 1 ? "Invalid UserName, Please enter a valid UserName" : "Please enter the email address registered with SportsKingdom. You will receive a confirmation code"}
                                    />
                                </Grid>}
                                {(this.state.page === 1) && <Grid container spacing={2}> <Grid item xs={12}>
                                    <IconButton onClick={this.handleBack} edge="start" aria-label="mark">
                                        <ArrowBack />
                                    </IconButton>

                                    <TextField
                                        className={classes.textBox}
                                        variant="outlined"
                                        fullWidth
                                        id="code"
                                        label="Code"
                                        name="code"
                                        value={this.state.code}
                                        onChange={this.handleInputChange}
                                        error={this.state.invalid}
                                        helperText={this.state.invalid ? "Invalid Code" : "Please enter the code you received on  " + `${this.state.code_destination}`}
                                    />
                                </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="password"
                                            className={classes.textBox}
                                            variant="outlined"
                                            fullWidth
                                            id="newpassword1"
                                            label="New Password"
                                            name="newpassword1"
                                            value={this.state.newpassword1}
                                            onChange={this.handleInputChange}
                                            error={this.state.invalidPassword && this.state.newpassword1 < 6}
                                            helperText={this.state.passwordError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            type="password"
                                            className={classes.textBox}
                                            variant="outlined"
                                            fullWidth
                                            id="newpassword2"
                                            label="Confrim Password"
                                            name="newpassword2"
                                            value={this.state.newpassword2}
                                            onChange={this.handleInputChange}
                                            error={this.state.invalidPassword && this.state.newpassword2 < 6}
                                            helperText={this.state.passwordError}
                                        />
                                    </Grid> </Grid>}
                                {(this.state.page === 0 || this.state.page === 1) && <Button
                                    className={classes.textBox}
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary">
                                    Send
                        </Button>}
                                {(this.state.page === 2) && <Grid container spacing={2}>
                                    <Typography variant="h5" gutterBottom>
                                        Password Reset Completed
                             </Typography>
                                    <Typography variant="h6" gutterBottom>
                                        Please go back to SportsKingdom App and Login with your new password
                            </Typography>
                                </Grid>}
                            </Grid>
                        </form>
                    </div>
                </Container>
            </ThemeProvider>
        );
    }
}

export default withStyles(styles)(ResetPassword);